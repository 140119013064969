import React from 'react';
import './Header.css';

const Header = ({ showModal, setShowModal }) => {
  return (
    <header className="header">
      <div className="header-content">
        <h1>Welcome to SkyQuake Software</h1>
        <p>Your partner in innovative software solutions.</p>
        <p>We specialize in developing intelligent, custom-built applications tailored to meet your unique needs and challenges.</p>
        <p>Together, we can revolutionize your digital experience.</p>
        <button className="contact-btn" onClick={() => setShowModal(true)}>Get in touch</button>
      </div>
    </header>
  );
};

export default Header;
