import React, { useEffect, useState } from 'react';
import './Modal.css';

const Modal = ({ showModal, setShowModal }) => {
  const [formData, setFormData] = useState({ name: '', email: '', text: '' });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.modal')) {
        setShowModal(false);
      }
    };
    if (showModal) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showModal, setShowModal]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    window.alert('Message prepared successfully!');
    setShowModal(false);
  };

  const handleClick = () => {
    setShowModal(prev => !prev);
  };

  const email = `mailto:hello@skyquakesoftware.com?subject=Let's start a conversation`;
  const phone = `tel:903 932 3555`;

  return (
    <>
      {showModal ? (
        <div className="modal-background">
          <div className="modal">
            <button className="close-button" onClick={handleClick}>X</button>
            <h2>Let's start a conversation</h2>
            <p>We are here to assist you.</p> 
            <p>Feel free to contact us via email or phone call.</p>
            {/* <form onSubmit={handleSubmit}>
              <input type="text" name="name" placeholder="Name" required onChange={handleInputChange} />
              <input type="email" name="email" placeholder="Email" required onChange={handleInputChange} />
              <input type="text" name="phone" placeholder="Phone" required onChange={handleInputChange} />
              <input type="text" name="text" placeholder="How can we help?" required onChange={handleInputChange} />
              <button type="submit">Prepare Message</button>
            </form> */}
            <a href={email} className="email-button">Email Us</a>
            <a href={phone} className="phone-button">Call Us</a>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;

