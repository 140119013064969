import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavigationBar from './components/NavBar/NavigationBar';
import Header from './components/Header/Header'
import WhatWeDo from './components/WhatWeDo/WhatWeDo';
import OurProcess from './components/OurProcess/OurProcess';
import OurPrinciples from './components/OurPrinciples/OurPrinciples';
import Modal from './components/Modal/Modal';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

const App = () => {
  
  const [showModal, setShowModal] = useState(false);

  return (
    <Router>
      <NavigationBar onContactClick={() => setShowModal(true)} />
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <Header showModal={showModal} setShowModal={setShowModal} />
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <WhatWeDo />
      <OurProcess />
      <OurPrinciples />
      <Contact showModal={showModal} setShowModal={setShowModal}/>
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <Footer />

      {/* Define your routes here */}
      {/* <Switch>
           ...
         </Switch> */}
    </Router>
  );
};

export default App;
