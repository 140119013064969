import React, { useState } from 'react';
import './WhatWeDo.css';
import { useTransition, animated } from '@react-spring/web';

import FrontEndIcon from './eyeball.svg';
import BackEndIcon from './backend.svg';
import MobileIcon from './satellite.svg';
import WebManagementIcon from './ufo1.svg';

import RubyIcon from './ruby.svg'
import ReactIcon from './react.svg'
import PythonIcon from './python.svg'
import FutureIcon from './future.svg'

const WhatWeDo = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const transitions = useTransition(isExpanded, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 'auto' },
    leave: { opacity: 0, height: 0 },
  });

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="what-we-do" id="what-we-do">
      
      <h2>What We Do</h2>
      <div className="services">
        <div className="service">
          <img src={FrontEndIcon} alt="Front End Development Icon" />
          <h3>Front End Development</h3>
          <p>Transform your digital presence with visually captivating interfaces that not only look good but also engage your audience effectively.</p>
        </div>
        <div className="service">
          <img src={BackEndIcon} alt="Back End Development Icon" />
          <h3>Back End Development</h3>
          <p>Power your business with robust and scalable back-end architectures that are built to accommodate growth and complexity.</p>
        </div>
        <div className="service">
          <img src={MobileIcon} alt="Mobile Development Icon" />
          <h3>Mobile Development</h3>
          <p>Extend your reach beyond the desktop. Our mobile applications offer unparalleled user experiences, ensuring engagement on every platform.</p>
        </div>
        <div className="service">
          <img src={WebManagementIcon} alt="Web Management Icon" />
          <h3>Web Management</h3>
          <p>Unlock peace of mind with our end-to-end web management solutions that cover everything from regular updates to security and performance optimization.</p>
        </div>
      </div>
      <button onClick={handleExpand} className="technologies-button">Technologies Used</button>
      {transitions(
        (styles, item) =>
          item && (
            <animated.div style={styles} className="technologies-used">
              <div className="technology">
                <h3>React
                <img src={ReactIcon} alt="React Icon" />
                </h3>
                <p>Expertise in React allows us to craft interactive UIs with ease. What you're seeing now is a live testament to our proficiency.</p>
              </div>
              <div className="technology">
              <h3>
                <img src={RubyIcon} alt="Ruby Icon" />
                  Ruby on Rails
                </h3>
                <p>Whether it's a start-up MVP or a complex enterprise application, our Ruby on Rails skills can meet the challenge head-on.</p>
              </div>
              <div className="technology">
                <h3>python
                  <img src={PythonIcon} alt="Python Icon" />
                </h3>
                <p>Handling data-intensive tasks? Our Python experts leverage the language's capabilities to tackle big data and analytics seamlessly.</p>
              </div>
              <div className="technology">
              <h3>
                <img src={FutureIcon} alt="Future Icon" />
                  Future Tools
              </h3>
                <p>We're not just keeping up with the tech landscape, we're shaping it. Our team continuously adopts and integrates the latest innovations to ensure you stay ahead of the curve.</p>
              </div>
            </animated.div>
          )
      )}
      {/* <div className="testimonials">
        <h2>What Our Clients Say</h2>
        <div className="testimonial">
          <p>"I couldn't be happier with the work done by this team. They not only delivered what was promised but also exceeded my expectations!"</p>
          <h4>- John Doe, CEO of XYZ Co.</h4>
        </div>
        <div className="testimonial">
          <p>"The technical expertise and dedication of this team is impressive. They helped us revamp our website and the results are amazing!"</p>
          <h4>- Jane Doe, Founder of ABC Inc.</h4>
        </div>
      </div> */}
    </section>
  );
};

export default WhatWeDo;
