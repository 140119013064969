import React from 'react';
import './Contact.css';

import EmailIcon from './email.svg';
import PhoneIcon from './phone.svg';
import TexasIcon from './texas.svg';

const ContactSection = ({ showModal, setShowModal }) => {

  const email = `mailto:hello@skyquakesoftware.com?subject=Let's start a conversation`;
  const phone = `tel:903 932 3555`;

  return (
    <section className="contact-section">
      <h2>Let's Build Something Great Together</h2>
      <h3>Your ideas are just a step away from becoming a reality. Get in touch with us and we'll help you transform them into a remarkable solution.</h3>
      <div className="contact-info">
        <div className="contact-item">
          <img src={EmailIcon} alt='Email Icon' className="icon"/>
          <p>Email</p>
          <a href={email}>hello@skyquakesoftware.com</a>
        </div>
        <div className="contact-item">
          <img src={PhoneIcon} alt='Phone Icon' className="icon"/>
          <p>Call</p>
          <a href={phone} alt="Phone Number">903 932 3555</a>
        </div>
        {/* <div className="contact-item">
          <img src={TexasIcon} alt='Map Icon' className="icon"/>
          <p>Built with pride in</p> 
          <p> Texas, USA </p>
        </div> */}
      </div>
    </section>
  );
};

export default ContactSection;
