import React from 'react';
import './OurPrinciples.css';

const principles = [
  {
    title: 'Communication',
    description: 'We believe that effective communication is the cornerstone of any successful project. We maintain open lines of communication with our clients at all stages of development to ensure that we are always aligned with their needs and expectations.'
  },
  {
    title: 'Flexibility',
    description: 'In the ever-changing world of technology, flexibility is key. We are always ready to adapt to new challenges and changes in the project requirements. Our agile approach ensures that we can make changes quickly and efficiently, without compromising on quality.'
  },
  {
    title: 'Precision',
    description: 'We are committed to delivering high-quality software. Our development process involves rigorous testing to ensure that the final product is free of bugs and runs smoothly on all intended devices and platforms. We pay attention to the smallest details to ensure that everything is perfect.'
  },
  {
    title: 'Affordability',
    description: 'We understand the financial constraints that many businesses face, especially small and medium-sized enterprises. That’s why we offer a range of affordable solutions that do not compromise on quality or performance. Our goal is to provide value for money and help our clients achieve their objectives within their budget.'
  }
];

const OurPrinciples = () => {
  return (
    <section className="our-principles">
      <div className="principles">
      <h2>Our Mission</h2>
        {principles.map((principle, index) => (
          <div key={index} className="principle">
            <h3>{principle.title}</h3>
            <p>{principle.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurPrinciples;
