import React from 'react';
import './Footer.css';
import logo from './alien.png'; // update with your logo path

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2023 Skyquake Software. All Rights Reserved.</p>
        <a href="#top" className="logo-link">
        <h1>Skyquake Software</h1>
          <img src={logo} alt="Logo" className="footer-logo" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
// skyquake/public/alien.png