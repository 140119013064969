import React from 'react';
import './OurProcess.css';

const OurProcess = () => {
  return (
    <section className="our-process" id="our-process">
      <div className="process-steps">
        <h2>The Process</h2>
        <div className="step">
          <h3>Design</h3>
          <p>Our design process involves a thorough understanding of your business needs and goals. We create wireframes, mockups, and prototypes to visualize the final product and ensure it aligns with your expectations and objectives.</p>
        </div>
        <div className="step">
          <h3>Develop</h3>
          <p>We follow an agile development approach to build the software incrementally. This involves regular communication and feedback sessions with you to ensure the product is developing in the right direction.</p>
        </div>
        <div className="step">
          <h3>Deploy</h3>
          <p>Once the development is complete, we move the software to a production environment. We conduct thorough testing to ensure it is bug-free and runs smoothly on all intended devices and platforms.</p>
        </div>
        <div className="step">
          <h3>Support</h3>
          <p>Our job doesn't end with deployment. We provide ongoing support to ensure the software continues to run smoothly. We also offer training and documentation to help your team get the most out of the new software.</p>
        </div>
      </div>
    </section>
  );
};

export default OurProcess;
